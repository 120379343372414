import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import { useNavigate } from 'react-router-dom'
import { signInWithEmail, signUpWithEmail } from 'services/SupaService'
import appConfig from 'configs/app.config'

function useAuth() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { token, signedIn } = useSelector((state) => state.auth.session)

    //attempt a sign in 
    const signIn = async (values) => {

        try {
            
            //login
            const resp  = await signInWithEmail(values.userName, values.password);
            
            //if we didn't grab, toss an error
            if(resp.status != 'success') throw "Account does not exist";
     
            //fire success hook
            dispatch(onSignInSuccess(resp.data.session['access_token']))

            //store data in session
            if (resp.data.user) dispatch(setUser(resp.data.user ))

            //return arbitrary success
            return { status: 'success', message: '', }

        } catch (errors) {

            //return error
            return { status: 'failed', message: errors?.response?.data?.message || errors.toString(),}

        }
    }

    //attempt a signup 
    const signUp = async (values) => {

        try {
            //const resp = await apiSignUp(values)
            const resp = await signUpWithEmail(values.email, values.password);
            console.log(resp)

            //if we didn't grab, toss an error
            if(resp.status != 'success') throw resp.message.message;

            if (resp.data) {
                

                //fire success hook
                dispatch(onSignInSuccess(resp.data.session['access_token']))

                //store data in session
                if (resp.data.user) dispatch(setUser(resp.data.user ))

                return { status: 'success', message: '', }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        handleSignOut()
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut,
    }
}

export default useAuth
