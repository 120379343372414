import { createClient } from "@supabase/supabase-js";

//connect to supabase
const supabase = createClient("https://czfedeplminnpapweglk.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImN6ZmVkZXBsbWlubnBhcHdlZ2xrIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODE4NTIwODIsImV4cCI6MTk5NzQyODA4Mn0.FL_HmYYrveZbUrK4FBOe-7tJW3uefi5jB2EPlHirDq4");


export async function forgotPassword(email){
      //prepare response
  const response = {};
  response.data = {};
  response.status = 'failed';
  
  const { data, error } = await supabase.auth.resetPasswordForEmail(email)


  //failed to create
  if(error){
    console.log("error" , error);
    response.message = error;
    return response;
  }

  //if success, grab data
  response.status = 'success';
  response.data = data;

  return response;


}
/**
 * get account information
 * @return {[type]} [description]
 */
export async function getAccountData(uid) {

    //grab data from specific user
    const { data } = await supabase.from("users").select().eq('uid', uid);
    
    return data;
  
}


/**
 * get account integrations
 * @return {[type]} [description]
 */
export async function apiGetAccountSettingIntegration(uid, type) {


    //grab data from specific user
    const { data } = await supabase.from("integrations").select(type).eq('uid', uid);
   
    return data[0][type];
    
  
}


/**
 * get account integrations
 * @return {[type]} [description]
 */
export async function apiGetAccountSettingIntegrationData(uid) {

    //prepare response
    const response = {};

    //grab data from specific user
    const { data } = await supabase.from("integrations").select().eq('uid', uid);
    
    //grab from array
    const integrations = data[0];

    //list all available or installed integrations
    const available = [];
    const installed = [];

    
    const s3_integration = {};
    s3_integration.name = "Amazon S3";
    s3_integration.slug = "amazon_s3";
    s3_integration.desc = "Cloud storage by AWS";
    s3_integration.img = "/img/thumbs/aws-s3.png";

    const notion_integration = {};
    notion_integration.name = "Notion";
    notion_integration.slug = "notion";
    notion_integration.desc = "Productivity and collaboration platform.";
    notion_integration.img = "/img/thumbs/notion.png";

    //TODO add support for gd
    //const gd_integration = {};
    //gd_integration.name = "Google Drive";
    //gd_integration.slug = "google_drive";
    //gd_integration.desc = "Cloud storage by Google";
    //gd_integration.img = "/img/thumbs/google-drive.png";
    //(integrations?.google_drive) ? installed.push(gd_integration) : available.push(gd_integration);

    //add to relevant object based on current setup
    (integrations?.amazon_s3) ? installed.push(s3_integration) : available.push(s3_integration);
    (integrations?.notion) ? installed.push(notion_integration) : available.push(notion_integration);

    //add to object
    response.installed = installed;
    response.available = available;


    return response;
    
  
}



export async function removeIntegration(uid, name){

   //prepare response
  const response = {};
  response.data = {};
  response.status = 'failed';
  
  //prepare payload
  var payload = {};
  payload.uid = uid;
  payload[name] = null;

  const { data, error } = await supabase.from('integrations').upsert(payload).select();

  //failed to create
  if(error){
    console.log("error" , error);
    response.message = error;
    return response;
  }

  //if success, grab data
  response.status = 'success';
  response.data = data;

  return response;
  

}


export async function storeIntegration(uid, name, json){

   //prepare response
  const response = {};
  response.data = {};
  response.status = 'failed';
  
  //prepare payload
  var payload = {};
  payload.uid = uid;
  payload[name] = json;

  const { data, error } = await supabase.from('integrations').upsert(payload).select();

  //failed to create
  if(error){
    console.log("error" , error);
    response.message = error;
    return response;
  }

  //if success, grab data
  response.status = 'success';
  response.data = data;

  return response;
  

}




export async function signUpWithEmail(email, password){

   //prepare response
  const response = {};
  response.data = {};
  response.status = 'failed';
  
  const { data, error } = await supabase.auth.signUp({ email: email, password: password})

  //failed to create
  if(error){
    console.log("error" , error);
    response.message = error;
    return response;
  }

  //if success, grab data
  response.status = 'success';
  response.data = data;

  return response;
  

}

/**
 * get account billings
 * @return {[type]} [description]
 */
export async function apiGetAccountBillings(uid) {


    //grab data from specific user
    const { data } = await supabase.from("billings").select().eq('uid', uid);
 
    const response = {};
    response.billings = data;
   
    return response;
    
  
}


/**
 * get account backups
 * @return {[type]} [description]
 */
export async function apiGetAccountIntegrationBackups(uid) {

    //grab data from specific user
    const { data } = await supabase.from("backups").select().eq('uid', uid).order('id', { ascending: false });

    //pretty data 
    data.forEach(function(item) { 
        item.size_mb = (item.size / 1048576).toFixed(2);

        let date = new Date(item.created_at);
        let options = { year: 'numeric', month: 'long', day: 'numeric' };
        let formattedDate = date.toLocaleDateString('en-US', options);

        item.created_at = formattedDate;

    });


    const response = {};
    response.backups = data;
    

    return response;
    
  
}

/**
 * sign into an account with an email and password
 * @param  {[type]} email    [description]
 * @param  {[type]} password [description]
 * @return {[type]}          [description]
 */
export async function signInWithEmail(email, password) {

  //prepare response
  const response = {};
  response.data = {};
  response.status = 'failed';
  response.message = 'User not found';

  //login
  const { data, error } = await supabase.auth.signInWithPassword({ email: email, password: password})

  //return error if we failed
  if(error) return response;
  
  //if success, grab data
  response.status = 'success';
  response.data = data;

  return response;
}